import React, { useEffect, useState } from "react";
import Button from "@/components/UI/Button";
import PostModal from "@/components/UI/Button/PostModal";
import PostCard from "@/components/UI/Button/PostCard";
import { deleteAdminPost, getAllAdminPost } from "@/api/adminApi";
import Spinner from "@/components/UI/Spinner";
import toast from "react-hot-toast";

const ProjectsPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [postType, setPostType] = useState("Post");
  const [editData, setEditData] = useState(null);
  const [editingPostIndex, setEditingPostIndex] = useState<number | null>(null); // To track if we're editing a post

  const [posts, setPosts] = useState([]);
  // console.log("🐱‍👤✨ ~ posts:", posts);

  // ~ getting admin post data
  const handleGetAdminPost = async () => {
    setIsLoading(true);
    await getAllAdminPost().then((res) => {
      if (res.success) {
        setPosts(res.posts);
      } else {
        setPosts([]);
        // Handle error
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    handleGetAdminPost();
  }, []);

  // const handlePostSubmit = (postData: {
  //   name: string;
  //   description: string;
  // }) => {
  //   // if (editingPostIndex !== null) {
  //   //   // Edit existing post
  //   //   const updatedPosts = [...posts];
  //   //   updatedPosts[editingPostIndex] = postData; // Update the post at the specified index
  //   //   setPosts(updatedPosts);
  //   //   setEditingPostIndex(null); // Reset the edit state
  //   // } else {
  //   //   // Add new post
  //   //   setPosts((prevPosts) => [...prevPosts, postData]);
  //   // }
  //   setIsModalOpen(false); // Close modal after submission
  // };
  // const handleEditPost = (index: number) => {
  //   setEditingPostIndex(index);
  //   setIsModalOpen(true);
  // };

  // Handle deleting a post

  const handleDeletePost = async (id: number) => {
    await deleteAdminPost(id).then((res) => {
      console.log("🐱‍👤✨ ~ awaitdeleteAdminPost ~ res:", res);
      if (res.success) {
        toast.success(res.message || "Post Deleted successfully");
        handleGetAdminPost();
      } else {
        toast.error(res.message || "Failed to delete the post");
      }
    });
  };

  // ~ handle create post function
  const handleCreatePost = () => {
    setPostType("Post");
    setIsModalOpen(true);
  };

  // ~ handle edit post
  const handleEditPost = (post: any) => {
    setEditData(post);
    setPostType("Edit");
    setIsModalOpen(true);
  };

  // ~ getting the remaining details
  const [postViewData, setPostViewData] = useState(null);

  const gettingPostViewData = async () => {
    try {
      const proxyUrl = "https://www.steempro.com"; // Replace with your proxy server URL
      const postViewResponse = await fetch(`${proxyUrl}/api/steem/promoted`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await postViewResponse.json();
      console.log("🐱‍👤✨ ~ gettingPostViewData ~ data:", data);
    } catch (error) {
      console.log("🐱‍👤✨ ~ gettingPostViewData ~ error:", error);
      setPostViewData(null);
    }
  };

  useEffect(() => {
    gettingPostViewData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex items-center justify-between">
              <h4>All Posts</h4>
              <Button secondary rounded onClick={handleCreatePost}>
                Create Post
              </Button>
            </div>

            <div className="p-8">
              <h2 className="mb-6 text-2xl font-bold">Feed</h2>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {posts?.length === 0 && (
                  <h5 className="flex text-center">No Post Found</h5>
                )}
                {posts?.map((post: any, index: number) => (
                  <div key={index}>
                    <PostCard
                      name={post?.name}
                      description={post?.description}
                    />
                    <div className="flex gap-2 mt-2">
                      <Button
                        primary
                        rounded
                        // onClick={() => handleEditPost(index)}
                        onClick={() => handleEditPost(post)}
                      >
                        Edit
                      </Button>
                      <Button
                        classNames="bg-red-500 text-white"
                        rounded
                        onClick={() => handleDeletePost(post?.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modal for creating a post */}
      <PostModal
        // onSubmit={handlePostSubmit}
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingPostIndex(null); // Reset edit state on modal close
        }}
        refetch={() => handleGetAdminPost()}
        postType={postType}
        editData={editData} // Pass post data if editing
        initialData={
          editingPostIndex !== null ? posts[editingPostIndex] : undefined
        } // Pass post data if editing
      />
    </div>
  );
};

export default ProjectsPage;
