import axios from "axios";

// const BaseUrl = process.env.BACKEND_URL;
const BaseUrl = "https://backend.steemchat.org/api";
// const BaseUrl = "http://localhost:3003/api";

// ~ admin login api
export const adminLogin = async (data) => {
  try {
    const response = await axios.post(`${BaseUrl}/admin/login`, data);

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response?.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ adminLogin ~ error:", error);
    return error?.response?.data
      ? error.response.data
      : error?.response?.message;
  }
};

// ~ admin login api
export const adminLogout = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${BaseUrl}/admin/logout`, {
      headers: {
        token: token,
      },
    });

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ adminLogout ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ add post for admin
export const addAdminPost = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(`${BaseUrl}/admin-posts`, data, {
      headers: {
        token: token,
      },
    });

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ addAdminPost ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ delete post for admin
export const deleteAdminPost = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(`${BaseUrl}/delete-post/${id}`, {
      headers: {
        token: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ deleteAdminPost ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ edit post for admin
export const editAdminPost = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(`${BaseUrl}/edit-admin-post`, data, {
      headers: {
        token: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ editAdminPost ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ get week registered users
export const getAllAdminPost = async () => {
  const token = localStorage.getItem("token");
  console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(`${BaseUrl}/get-admin-posts`, {
      headers: {
        token: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ getAllAdminPost ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ refresh JWT token api
export const refreshToken = async () => {
  const token = localStorage.getItem("token");
  console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(`${BaseUrl}/refreshToken`, {
      headers: {
        token: token,
      },
    });

    console.log("🐱‍👤✨ ~ refreshToken ~ response:", response); // Log the entire response object

    if (response && response?.data) {
      return response?.data;
    } else {
      console.error("🐱‍👤✨ ~ refreshToken ~ Response data is undefined");
      return null;
    }
  } catch (error) {
    console.log("🐱‍👤✨ ~ refreshToken ~ error:", error);
  }
};
// export const refreshToken = async () => {
//   const token = localStorage.getItem("token");
//   console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
//   try {
//     const response = await axios.get(`${BaseUrl}/refreshToken`, {
//       headers: {
//         token: token,
//       },
//     });

//     //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
//     return response.data;
//   } catch (error) {
//     console.log("🐱‍👤✨ ~ adminLogin ~ error:", error);
//     return error.response.data ? error.response.data : error.response.message;
//   }
// };

// ~ get today registered users
export const getTodayUsers = async () => {
  const token = localStorage.getItem("token");
  console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(`${BaseUrl}/users-registered-today`, {
      headers: {
        token: token,
      },
    });

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ getTodayUsers ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ get week registered users
export const getWeekUsers = async () => {
  const token = localStorage.getItem("token");
  console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(`${BaseUrl}/users-registered-last-week`, {
      headers: {
        token: token,
      },
    });

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ getWeekUsers ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ get month registered users
export const getMonthUsers = async () => {
  const token = localStorage.getItem("token");
  console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(
      `${BaseUrl}/users-registered-last-30-days`,
      {
        headers: {
          token: token,
        },
      }
    );

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ getMonthUsers ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};

// ~ get month registered users
export const getUsersLast12Months = async () => {
  const token = localStorage.getItem("token");
  console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(`${BaseUrl}/users-yearly`, {
      headers: {
        token: token,
      },
    });

    //     console.log("🐱‍👤✨ ~ adminLogin ~ response:", response);
    return response.data;
  } catch (error) {
    console.log("🐱‍👤✨ ~ getUsersLast12Months ~ error:", error);
    return error.response.data ? error.response.data : error.response.message;
  }
};
