// App.js
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";

// Importing auth services
import { QueryProvider } from "@services/QueryProvider";
import "./utils/global";

// Importing styling
import Layout from "./pages/layout";

import RefreshProvider from "./provider/RefreshProvider";

function App() {
  return (
    <QueryProvider>
      <Router>
        <Toaster />
        <RefreshProvider>
          <Layout />
        </RefreshProvider>
      </Router>
    </QueryProvider>
  );
}

export default App;
