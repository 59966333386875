import { refreshToken } from "@/api/adminApi";
import Spinner from "@/components/UI/Spinner";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RefreshProvider = ({ children }: any) => {
  const userLoggedIn = localStorage.getItem("loggedIn") || "false";
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(
    userLoggedIn === "true" ? true : userLoggedIn === "false" ? false : false
  );

  useEffect(() => {
    if (location.pathname === "/login" && loggedIn) {
      navigate("/dashboard");
    }
  }, [location, loggedIn, navigate]);

  useEffect(() => {
    const handleRefreshToken = async () => {
      setIsLoading(true);
      const res = await refreshToken();
      if (res?.success) {
        localStorage.setItem("token", res.token);
        localStorage.setItem("loggedIn", "true");

        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      setIsLoading(false);
    };

    // Call the refresh token API only if not on the login page
    if (location.pathname !== "/login") {
      handleRefreshToken();
    } else {
      // If on login page, set loading to false immediately
      setIsLoading(false);
    }
  }, [location.pathname]);

  // Show loading state while checking login status
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner size="w-16 h-16" />;
      </div>
    );
  }

  // Redirect to login if not logged in and not on the login page
  if (!loggedIn && location.pathname !== "/login") {
    // return <Navigate to="/login" replace />;
    return navigate("/login");
  }

  // Render children if logged in
  return <>{children}</>;
};

export default RefreshProvider;
