import { useState } from "react"
import { IcTotalFinancingReceived } from "@/assets/Icons";
const BalanceCard = ({title,value}:any) => {
  return (
    <div className="bg-white p-4 grow flex flex-column rounded-lg">
      <div className="flex flex-row gap-4 items-start">
        <IcTotalFinancingReceived />
        <div className="flex flex-column gap-2">
          <span className="body-small-regular text-monochrome-30">{title}</span>
          {
            <h1>{value}</h1>
          }
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
