import IcWallet from "./ic_wallet";
import IcTotalFinancingReceived from "./ic_total_financing_received";
import IcWithdrowal from "./ic_withdrowal";
import IcProjectCompleted from "./ic_project_completed";
import IcProjectInreview from "./ic_project_in_review";
import IcFilter from "./ic_filter";
import IcBuy from "./ic_buy";
import IcPreviousPage from "./ic_previous_page";
import IcNextPage from "./ic_next_page";
import IcUser from "./ic_user";
import IcCompanySettings from "./ic_company_settings";
import IcArrowUp from "./ic_arrow_up";
import IcKycNeeded from "./ic_kyc_need";
import IcLock from "./ic_lock";
import IcCopy from "./ic_copy";
import IcFolder from "./ic_folder";
import IcAcArrowUp from "./ic_ac_arrow_up";
import IcAcArrowDown from "./ic_ac_arrow_down";
import IcFilePlaceholder from "./ic_file_placeholder";
import IcPercentage from "./ic_percentage";
import IcRedirect from "./ic_redirect";
import BegdeIcon from "./ic_project_bedge";
import ProjectWarning from "./ic_project_warning";



export {
    ProjectWarning,
    BegdeIcon,
    IcWallet,
    IcTotalFinancingReceived,
    IcWithdrowal,
    IcProjectCompleted,
    IcProjectInreview,
    IcFilter,
    IcBuy,
    IcPreviousPage,
    IcNextPage,
    IcUser,
    IcCompanySettings,
    IcArrowUp,
    IcKycNeeded,
    IcLock,
    IcCopy,
    IcFolder,
    IcAcArrowUp,
    IcAcArrowDown,
    IcFilePlaceholder,
    IcPercentage,
    IcRedirect
}