import { useLocation } from "react-router-dom";
import { classNames } from "@/utils";
import { NavBarList } from "@/constants/NavMenu";
import { ROUTES } from "@/constants";
import Button from "./UI/Button";

import { useContext, useState } from "react";
import NotificationCard from "./NotificationsCard";


const Header = () => {
  const { pathname } = useLocation();
  const [showNotificationCard, setShowNotificationCard] = useState(false); // State for NotificationCard visibility
  const isProjectOverviewPage = pathname === ROUTES.OVERVIEW;



  const onClickBack = () => {
    window.history.back();
  };

  const handleNotificationClick = () => {
    setShowNotificationCard((prev) => !prev);
  };

  return (
    <>
      <header
        className={classNames("h-16 px-3 py-3 sm:py-0 bg-monochrome-5 flex justify-between items-center gap-y-2 sticky top-0 z-[20]", )}
      >
        <h3 className="my-auto">
          {isProjectOverviewPage && (
            <div className="flex items-center gap-2">
              <Button classNames="bg-white h-fit w-fit !p-[10px] leading-[10px]" rounded onClick={onClickBack}>
                {"<"}
              </Button>
              <p className="text-xs font-normal">Go Back</p>
            </div>
          )}
          {
            NavBarList.find((item: any) => {
              return item.link === pathname;
            })?.title
          }
          {pathname === "/projects/getCapital" && "Get Capital"}
        </h3>
     
      </header>

      {/* {showNotificationCard && */}
      <NotificationCard onClose={() => setShowNotificationCard(false)} />
      {/* } */}
    </>
  );
};

export default Header;
