import { ROUTES } from ".";
import { IcContactSupport, IcDashboard, IcFillContactSupport, IcFillDashboard, IcFillProjects, IcFillSettings, IcFillTransactions, IcProjects, IcSetting, IcTransactions } from "@/assets/menu";

export const NavBarList = [
    {
      title: 'Dashboard',
      icon: <IcDashboard />,
      iconWhenSelected: <IcFillDashboard />,
      link: ROUTES.DASHBOARD
    },
    {
      title: 'Posts',
      icon: <IcProjects />,
      iconWhenSelected: <IcFillProjects />,
      link: ROUTES.PROJECTS
    },

  ]