import React, { useState, useEffect } from "react";
import Button from "@/components/UI/Button";
import { Modal } from "flowbite-react";
import { addAdminPost, editAdminPost } from "@/api/adminApi";
import toast from "react-hot-toast";
import Spinner from "../Spinner";
import axios from "axios";

const PostModal = ({
  show,
  onClose,
  editData,
  postType,
  refetch,
  initialData,
}) => {
  console.log("🐱‍👤✨ ~ postType:", postType);
  console.log("🐱‍👤✨ ~ editData:", editData || "no edit data");
  const [name, setName] = useState("");
  const [postName, setPostName] = useState("");
  const [description, setDescription] = useState(
    postType === "Edit" ? editData?.description : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [bannerImage, setFileImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [postUrl, setPostUrl] = useState("");

  // useEffect(() => {
  //   if (initialData) {
  //     setName(initialData.name);
  //     setPostName(initialData.postName);
  //   } else {
  //     setName("");
  //     setPostName("");
  //   }
  // }, [initialData, show]); // Reset form when modal opens

  const handleSubmit = async () => {
    if (postType === "Post") {
      if (name && postName) {
        const data = {
          name: name,
          postName: postName,
          bannerImage: bannerImage,
          postUrl: postUrl,
        };
        // console.log("🐱‍👤✨ ~ handleSubmit ~ data:", data);
        await addAdminPost(data).then((res) => {
          setIsLoading(true);
          if (res.success) {
            toast.success(res.message || "Post Created Success");
            setName("");
            setPostName("");
            setFileImage(null);
            setPostUrl("");
            onClose();
            refetch();
          } else {
            toast.error(res.message || "Failed to create post");
          }
        });
        setIsLoading(false);
      }
      return;
    }
    if (postType === "Edit") {
      if (name || bannerImage || postUrl || postName) {
        const data = {
          postId: editData.id,
          name: name || editData.name,
          postName: postName || editData.postName,
          bannerImage: bannerImage || editData.bannerImage,
          postUrl: postUrl || editData.postUrl,
        };
        console.log("🐱‍👤✨ ~ handleSubmit ~ data:", data);
        // onSubmit({ id, name, description });
        await editAdminPost(data).then((res) => {
          setIsLoading(true);
          if (res.success) {
            toast.success(res.message || "Post Updated Success");
            onClose();
            //  setName("");
            //  setPostName("");
            //  setFileImage(null);
            //  setPostUrl("");
            refetch();
          } else {
            toast.error(res.message || "Failed to update post");
          }
        });
        setIsLoading(false);
      }
      return;
    }
  };

  const handleImageChange = async (currentFile) => {
    setImageName(currentFile?.name);

    if (currentFile && currentFile.type.startsWith("image/")) {
      const formData = new FormData();
      formData.append("image", currentFile); // Append the image file

      try {
        setImageLoading(true);
        const response = await axios.post(
          "https://api.imgbb.com/1/upload?key=fa12b9c60b4c4a630acc7018183f3b22",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(response.data); // Log the response data
        setFileImage(response.data?.data?.image?.url);
        console.log(
          "🐱‍👤✨ ~ handleImageChange ~ response.data?.data?.image?.url:",
          response.data?.data?.image?.url
        );
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setImageLoading(false);
      }
    } else {
      console.error("Selected file is not an image.");
    }
  };

  useEffect(() => {
    if (postType === "Edit") {
      setName(editData.name);
      setPostName(editData.postName);
      setFileImage(editData.bannerImage);
      setPostUrl(editData.postUrl);
    }
    if (postType === "Post") {
      setName("");
      setPostName("");
      setFileImage(null);
      setPostUrl("");
    }
  }, [postType, editData]);

  return (
    <Modal show={show} onClose={onClose} position={"center"} size={"2xl"}>
      <Modal.Body className="p-6">
        <section className="flex flex-col items-center gap-6">
          <h3 className="text-xl font-semibold">
            {initialData ? "Edit Post" : "Create a Post"}
          </h3>
          {/* user name */}
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              User Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder={
                postType === "Edit" ? editData?.name : "Enter your name"
              }
              required
            />
          </div>

          {/* post name */}
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              Post Name
            </label>
            <input
              type="text"
              value={postName}
              onChange={(e) => setPostName(e.target.value)}
              className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder={
                postType === "Edit" ? editData?.postName : "Enter Post Name"
              }
              required
            />
          </div>

          {/* post url */}
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              Post Url
            </label>
            <input
              type="text"
              value={postUrl}
              onChange={(e) => setPostUrl(e.target.value)}
              className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder={
                postType === "Edit" ? editData?.postUrl : "Enter your Url"
              }
              required
            />
          </div>

          {/* banner image */}
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              Banner
            </label>
            <div className="w-full h-auto min-h-[8vh] relative p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 flex justify-center items-center ">
              <input
                accept="image/*"
                type="file"
                name="fileInput"
                size="60"
                disabled={imageLoading}
                onChange={(e) => handleImageChange(e.target.files[0])}
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" // Hide the input but keep it functional
                required
              />
              <button
                type="button"
                className={`w-full h-full flex items-center justify-center rounded-lg ${
                  imageLoading ? "cursor-not-allowed" : "cursor-pointer"
                } transition duration-200`}
                onClick={() =>
                  document.querySelector('input[name="fileInput"]').click()
                } // Trigger file input on button click
              >
                {imageLoading ? (
                  <span>Loading...</span>
                ) : (
                  <>
                    {(postType === "Edit" && editData?.bannerImage) ||
                    bannerImage ? (
                      <img
                        src={bannerImage || editData.bannerImage}
                        alt="Banner Preview"
                        className="object-fit w-[30vw] h-[20vh] rounded-lg"
                      />
                    ) : (
                      <span>Select Banner Image</span>
                    )}
                  </>
                )}
              </button>
            </div>
          </div>

          {/* <div className="w-full">
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder={
                postType === "Edit"
                  ? editData?.description
                  : "Write a description..."
              }
              rows={4}
              required
            />
          </div> */}
          <div className="flex w-full gap-4">
            <Button primary rounded onClick={onClose} classNames="w-full">
              Cancel
            </Button>
            <Button
              secondary
              rounded
              onClick={handleSubmit}
              classNames="w-full"
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : postType}
              {/* {initialData ? "Update" : "Post"} */}
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default PostModal;
