import IcProjects from "./ic_projects";
import IcDashboard from "./ic_dashboard";
import IcFillDashboard from "./ic_fill_dashboard";
import IcFillProjects from "./ic_fill_projects";
import IcTransactions from "./ic_transactions";
import IcFillTransactions from "./ic_fill_transactions";
import IcContactSupport from "./ic_contact_support";
import IcFillContactSupport from "./ic_fill_contact_support";
import IcSetting from "./ic_setting";
import IcFillSettings from "./ic_fill_settings";
import IcClose from "./ic_close";
import IcHamburger from "./ic_hamburger";
import IcNotification from "./ic_notification";
import IcDropdownChecked from "./ic_dropdown_checked";

export {
  IcProjects,
  IcDashboard,
  IcFillDashboard,
  IcFillProjects,
  IcTransactions,
  IcFillTransactions,
  IcContactSupport,
  IcFillContactSupport,
  IcSetting,
  IcFillSettings,
  IcClose,
  IcHamburger,
  IcNotification,
  IcDropdownChecked
};
