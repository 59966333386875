import { IcClose, IcHamburger } from "@/assets/menu";
import { ROUTES } from "@/constants";
import { NavBarList } from "@/constants/NavMenu";
import { classNames } from "@/utils";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";

import { adminLogout } from "@/api/adminApi";
import { IcFillSettings } from "@/assets/menu";
import toast from "react-hot-toast";

const NavBar = ({ handleLinkClick, setShowLogoutModal }: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);

  // ~ handling logout
  const handleLogout = async () => {
    await adminLogout().then((res) => {
      setIsLoading(true);
      if (res.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("loggedIn");
        toast.success(res.message || "Logged Out");
        navigate("/login");
      } else {
        toast.error(res.message || "Failed to logout");
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      <nav className={classNames("z-[30] w-full lg:w-60 bg-white")}>
        <div className="h-full max-w-screen-xl flex flex-row lg:flex-col lg:justify-between flex-wrap lg:pl-8 py-5 !pt-[20px]">
          <div className="w-full">
            <div
              className={"w-full flex items-center justify-between lg:mb-10"}
            >
              {/* <NavLink to={ROUTES.DASHBOARD} className="flex"> */}
              <span className="flex flex-row items-center gap-1">
                <img
                  src={"../assets/Steemit-logo.png"}
                  className="cursor-pointer navbar-logo"
                  alt="penomo"
                />
                <h3>SteemChat</h3>
              </span>
              {/* </NavLink> */}
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-500 rounded-lg lg:hidden"
                aria-controls="navbar-default"
                aria-expanded={isNavOpen}
                onClick={() => setIsNavOpen(!isNavOpen)}
              >
                {isNavOpen ? (
                  <IcClose className="w-6 h-6" />
                ) : (
                  <IcHamburger className="w-6 h-6" />
                )}
              </button>
            </div>

            <div
              className={classNames(
                "fixed inset-0 bg-white z-50 top-[5rem] overflow-auto lg:static lg:bg-transparent lg:z-auto lg:block",
                { hidden: !isNavOpen }
              )}
              id="navbar-default"
            >
              <ul className="flex flex-col w-full py-2 lg:mt-0 lg:pl-0 lg:py-0 gap-y-8 lg:justify-center">
                {!isSetupProfilePage && (
                  <>
                    {NavBarList.map((item, index) => {
                      return (
                        <li key={"menu-" + index}>
                          <NavLink
                            as={NavLink}
                            to={item.link}
                            className={`pl-4 rounded flex justify-between bg-none`}
                            onClick={(e) => handleLinkClick(e, item.link)}
                          >
                            <div
                              className={
                                "flex justify-center items-center gap-4"
                              }
                            >
                              {pathname.includes(item.link)
                                ? item.iconWhenSelected
                                : item.icon}
                              <span
                                className={`flex my-auto text-xs leading-tight ${
                                  pathname.includes(item.link)
                                    ? "text-green-100"
                                    : "text-monochrome-50"
                                }`}
                              >
                                {item.title}
                              </span>
                            </div>
                            <p
                              className={`w-1.5 h-5 rounded-l-lg bg-green-100  ${
                                pathname.includes(item.link)
                                  ? "block"
                                  : "hidden"
                              }`}
                            ></p>
                          </NavLink>
                        </li>
                      );
                    })}

                    {/* logout button */}
                    <div className="flex justify-between pl-4 rounded bg-none">
                      <button
                        disabled={isLoading}
                        onClick={handleLogout}
                        className={`flex justify-center items-center gap-4 ${
                          isLoading && "cursor-not-allowed"
                        }`}
                      >
                        <IcFillSettings />
                        <span
                          className={`flex my-auto text-xs leading-tight text-monochrome-50 hover:text-green-100 hover:duration-200 `}
                        >
                          Logout
                        </span>
                      </button>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
