import { useState } from "react";

import { adminLogin } from "@/api/adminApi";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Make sure to install react-icons
import { useNavigate } from "react-router-dom";
import logo from "../assets/penomo_logo.svg";

const Login = () => {
  const navigate = useNavigate();

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // handling admin login
  const handleAdminLogin = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      console.log("Please enter both email and password");
      return; // stop execution if either email or password is empty
    }

    let data = {
      email: email,
      password: password,
    };

    // calling the login api
    await adminLogin(data).then((res) => {
      if (res?.success) {
        toast.success(res.message || "Successfully Logged in!");
        localStorage.setItem("token", res.token);
        navigate("/dashboard");
        // console.log("🐱‍👤✨ ~ awaitadminLogin ~ res.token:", res.token);
      } else {
        toast.error(res?.message || "Invalid Email or Password!");
      }
    });
  };

  return (
    <form action="" onSubmit={handleAdminLogin}>
      {/* outer container */}
      <div className="flex items-center justify-center w-full h-auto text-center">
        {/* main container */}
        <div className="flex flex-col items-center justify-center gap-y-[3vh] w-[35%] h-auto ">
          {/* heading */}
          {/* Adjust size as needed */}

          <span className="flex flex-row items-center gap-1 mt-[5vh]">
            <img
              src={"../assets/Steemit-logo.png"}
              className="cursor-pointer navbar-logo"
              alt="penomo"
            />
            <h1 className="text-2xl font-semibold text-balance">
              SteemChat Admin Login
            </h1>
          </span>
          {/* <h1 className="text-2xl font-semibold text-balance mt-[5vh]">
            Admin Login
          </h1> */}
          {/* input email */}
          <label htmlFor="email" className="w-full h-auto ">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-[6vh] p-2 text-sm border-gray-200 rounded-md outline-none border-1 focus:outline-none placeholder:text-sm focus:border-none"
              placeholder="Email"
            />
          </label>
          {/* input password */}
          {/* input password */}
          <div className="relative w-full">
            <label htmlFor="password" className="w-full h-auto">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-[6vh] p-2 text-sm border-gray-200 rounded-md outline-none border-1 focus:outline-none focus:border-none placeholder:text-sm pr-10"
                placeholder="Password"
              />
            </label>
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute transform -translate-y-1/2 right-3 top-1/2"
            >
              {showPassword ? (
                <FaEyeSlash className="w-4 h-4 text-gray-500" />
              ) : (
                <FaEye className="w-4 h-4 text-gray-500" />
              )}
            </button>
          </div>
          {/* submit button */}
          <button
            className="px-4 py-2 text-white transition-all duration-200 bg-blue-600 border-none rounded-md hover:bg-blue-700"
            type="submit"
          >
            Login
          </button>
        </div>
      </div>
    </form>
  );
};

export default Login;
