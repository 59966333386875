import React from 'react';



const PostCard = ({ name, description }) => {
    return (
        <div className="bg-monochrome-10 shadow-md rounded-lg p-6">
            <h4 className="text-xl font-semibold mb-2">{name}</h4>
            <p className="text-gray-600">{description}</p>
        </div>
    );
};

export default PostCard;
