import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

// Importing page components and ProtectedRoute
import HomePage from "./HomePage";
import DashboardPage from "./DashboardPage";
import ProjectsPage from "./Projects/ProjectsPage";

// Importing common components
import NavBar from "@/components/NavBar";
import Header from "@/components/Header";

import useStore from "@/store/useStore";
import { ROUTES } from "@/constants";
import Login from "./Login";
import { refreshToken } from "@/api/adminApi";

type Props = {};

const Layout = (props: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const { userData } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isSetupProfilePage = location.pathname === ROUTES.PROFILE_SETUP;
  // Track the current path
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const web3AuthToken = localStorage.getItem("web3AuthToken");
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
    }
  }, [userData]);

  useEffect(() => {
    // Update the current path when the location changes
    setCurrentPath(location.pathname);
  }, [location]);

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    path: string
  ) => {
    e.preventDefault();

    navigate(path);
  };

  // const token = localStorage.getItem("token") || "";
  // const loggedIn = localStorage.getItem("loggedIn") || "false";

  useEffect(() => {
    const handleRefreshToken = async () => {
      setIsLoading(true);
      const res = await refreshToken();
      if (res?.success) {
        localStorage.setItem("token", res.token);
        localStorage.setItem("loggedIn", "true");

        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setIsLoading(false);
    };

    handleRefreshToken();

    // if (token !== "" && loggedIn !== "false") {
    //   setIsLoggedIn(true);
    // } else {
    //   setIsLoggedIn(false);
    // }
  }, []);

  return (
    <>
      <main className="relative flex flex-col h-screen mx-auto lg:flex-row">
        {isLoggedIn && (
          <NavBar
            handleLinkClick={handleLinkClick}
            setShowLogoutModal={setShowLogoutModal}
          />
        )}
        {/* <section
          className={`w-full !h-fit relative  ${
            isLoggedIn && !isSetupProfilePage ? "xl:ml-60" : "xl:ml-60"
          }`}
        > */}
        <section
          className={`w-full !h-fit relative ${isLoggedIn && "xl:ml-60"}`}
        >
          <Header />
          <div className="p-4 mx-auto">
            <Routes>
              {/* <Route path={ROUTES.APP_ROOT} element={<HomePage />} /> */}
              <Route path={ROUTES.APP_ROOT} element={<DashboardPage />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
              <Route path={ROUTES.PROJECTS} element={<ProjectsPage />} />
            </Routes>
          </div>
        </section>
      </main>
    </>
  );
};

export default Layout;
