import IcCross from "@/assets/ic_cross";
import { IcPercentage } from "@/assets/Icons";
import { useNetwork } from "@/provider/NetworkContext";
import { fetchAllNotifications, readNotification } from "@/services/api";
import useStore from "@/store/useStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Loader from "./Loader";
import Button from "./UI/Button";

const NotificationCard = ({ onClose }) => {
  const { userData } = useStore();
  const { showAllNotification, setShowAllNotifications } = useNetwork();
  console.log(
    "🚀 ~ NotificationCard ~ showAllNotification:",
    showAllNotification
  );
  const queryClient = useQueryClient();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  console.log("🚀 ~ NotificationCard ~ showAll:", showAll);
  const [fadeIn, setFadeIn] = useState(false); // State for fade-in effect

  const { data: notifications = [], refetch } = useQuery({
    queryKey: ["getNotifications", userData?._id],
    queryFn: () => fetchAllNotifications(userData?._id),
    enabled: !!userData?._id,
    select: (data) => data.data,
    refetchOnWindowFocus: true,
  });

  const sortedNotifications = notifications.sort(
    (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()
  );

  const handleDeleteClick = async (notificationId) => {
    setDeleteLoading(true);
    try {
      await readNotification(notificationId);
      await refetch();
      queryClient.invalidateQueries(["getNotifications"]);
    } catch (error) {
      console.error("Error deleting notification:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    if (showAll) {
      setFadeIn(true); // Set fade-in when showAll becomes true
    } else {
      setFadeIn(false); // Reset fade-in when showAll becomes false
    }
  }, [showAll]);

  return (
    <div className="mb-0">
      {sortedNotifications.length > 0 && (
        <section className="flex flex-col col-span-2 gap-4 px-4 rounded-lg">
          {deleteLoading && (
            <div className="loader-center">
              <Loader
                isLoading={true}
                svgStyle={{ width: "2.5rem", height: "2.5rem" }}
              />
            </div>
          )}
          {sortedNotifications.length === 0 ? (
            <div className="text-center">No notifications available</div>
          ) : (
            <div className="flex flex-col w-full gap-4">
              {!showAll && !showAllNotification && (
                <div
                  className={`relative max-h-[28vh] base:max-h-[50vh] lg:max-h-[13vh] md:max-h-[17vh] overflow-hidden flex flex-col`}
                >
                  {sortedNotifications.slice(0, 3).map((item, index) => (
                    <div
                      key={item._id}
                      onClick={() => {
                        setShowAll(true);
                        setShowAllNotifications(true);
                      }}
                      className={`notification-card flex  justify-between items-center p-4 mb-4 rounded-lg shadow-lg w-full
                      ${
                        index === 0
                          ? "relative z-10 cursor-pointer"
                          : " md:-mt-16"
                      } 
                      ${
                        index !== 0
                          ? "relative notification-card1 -top-[7rem] md:-top-14"
                          : ""
                      } 
                      ${
                        index !== 0 && index !== 1
                          ? "relative notification-card1 -top-[14rem] md:-top-28"
                          : ""
                      }`}
                      style={{
                        height: index === 0 ? "auto" : "80%",
                        zIndex: sortedNotifications.length - index,
                      }}
                    >
                      <IcPercentage />
                      <div className="flex-wrap flex-grow ml-3 notification-content text-ellipsis">
                        <p className="text-sm">
                          {item.messages}
                          <span className="ml-2 text-monochrome-20">
                            {dayjs(item.createdAt).format("DD/MM/YYYY")}
                          </span>
                          <Button
                            rounded
                            classNames="block md:hidden flex self-start"
                            primary
                          >
                            <p className="flex text-nowrap">View Detail</p>
                          </Button>
                        </p>
                      </div>

                      <div className="flex flex-row items-center justify-center gap-2 ml-4">
                        <Button rounded classNames="hidden md:block" primary>
                          <p className="flex text-nowrap">View Detail</p>
                        </Button>
                        <IcCross
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(item._id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {(showAllNotification || showAll) && (
                <div
                  className={`flex flex-col bg-monochrome-10 p-2 md:p-6 rounded-md gap-4 transition-all duration-500 ${
                    showAll || showAllNotification
                      ? "animate-slideDown"
                      : "animate-slideUp"
                  }`}
                >
                  <div className="flex flex-row justify-between">
                    <h3>All Notifications</h3>
                    <button
                      onClick={() => {
                        setShowAll(false);
                        setShowAllNotifications(false);
                      }}
                      className="flex items-center text-black rounded-lg bg-monochrome-5"
                    >
                      <span className="p-2 text-xs">Show Less</span>
                    </button>
                  </div>
                  <div
                    className={`flex flex-col gap-2 md:gap-2 ${
                      showAll || showAllNotification
                        ? "max-h-[60vh] lg:max-h-[70vh] overflow-y-scroll"
                        : ""
                    }`}
                  >
                    {sortedNotifications
                      .slice(
                        0,
                        showAll || showAllNotification
                          ? sortedNotifications.length
                          : 1
                      )
                      .map((item) => (
                        <div
                          key={item._id}
                          className="flex items-center justify-between w-full p-4 mb-4 rounded-lg shadow-lg notification-card"
                        >
                          <IcPercentage />
                          <div className="flex-wrap flex-grow ml-3 notification-content text-ellipsis">
                            <p className="flex-wrap text-sm">
                              {item.messages}

                              <span className="ml-2 text-monochrome-20">
                                {dayjs(item.createdAt).format("DD/MM/YYYY")}
                              </span>
                              <Button
                                rounded
                                classNames="block md:hidden flex self-start"
                                primary
                              >
                                View Detail{" "}
                              </Button>
                            </p>
                          </div>

                          <div className="flex flex-row items-center justify-center gap-2 ml-4">
                            <Button
                              rounded
                              classNames="hidden md:block"
                              primary
                            >
                              View Detail{" "}
                            </Button>
                            <IcCross
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(item._id);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default NotificationCard;
